import React, {useState, useEffect} from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from "../assets/images/logo.png"
import { injectedConnector } from "../utils/connectors"
import { connectWallet } from "../utils/connectWallet";
import { useWeb3React } from "@web3-react/core";


const Header = () => {

  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
 }

 function closeNav() {
    document.getElementById("mySidenav").style.width = "0";

 }

  const [loaded, setLoaded] = useState(false)

  const {
    connector,
    library,
    account,
    chainId,
    activate,
    deactivate,
    active,
    errorWeb3Modal,
    active: networkActive, error: networkError, activate: activateNetwork
  } = useWeb3React();

  useEffect(() => {
    injectedConnector
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true)
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injectedConnector)
        }
      })
      .catch(() => {
        setLoaded(true)
      })
  }, [activateNetwork, networkActive, networkError])

  return (
    <nav class="navbar custom-nav">
        <Container>

         
            {/* <a class="navbar-brand">Navbar</a> */}
            <div className='logo_div'>
                <Link to={"/"}> <img src={logo} /> </Link>
            </div>

            <span onClick={(e) => openNav()} className="opennav">
                  <i class="fa fa-bars"></i>
            </span>
            <div id="mySidenav" className="gap-2 sidenav">
            <a href="javascript:void(0)" class="closebtn" onClick={(e) => closeNav()}>&times;</a>
              <ul class="d-flex"  className='nav_bar'>
                {/* <li><Link to={"/"}>Home</Link></li> */}
                {/* <li><Link to={"/staking"}>Staking</Link></li>
                <li><Link to={"#"}>NFTs</Link></li>
                <li><Link to={"#"}>Projects</Link></li> */}
               <li><a href='http://launch.litepad.cc/'  target="_blank" className='secondary-btn' >Launch App</a></li>
              
                
              </ul>
            </div>
          

      </Container>
      </nav>
  )
}

export default Header